import { $auth } from "@stores/auth"

import Container from "@components/Container"
import Image from "@components/Image"
import SpacingContainer from "@components/SpacingContainer"
import CmsLink from "@components/CmsLink"
import CmsButton from "@components/CmsButton"
import { useStore } from "@nanostores/react"

export interface ImageWithCtaProps extends Domain.Shared.DataTransferObjects.BlockBaseConfigData {
  media: Domain.Shared.DataTransferObjects.MediaResourceData
  headline: string | null
  cta: Domain.Shared.ValueObjects.Link[]
  backgroundColor: Domain.Shared.Enums.ColorPaletteEnum
}

const ImageWithCta = (props: ImageWithCtaProps): JSX.Element => {
  const { margin_top, margin_bottom, padding_top, padding_bottom, backgroundColor } = props
  const spacingMarginTop = margin_top?.value ?? "lg"
  const spacingMarginBottom = margin_bottom?.value ?? "none"
  const spacingPaddingTop = padding_top?.value ?? "none"
  const spacingPaddingBottom = padding_bottom?.value ?? "none"

  const { user } = useStore($auth)
  const isHidden = (props.hide_for_logged_in_users && user) || (props.hide_for_logged_out_users && !user)

  if (isHidden) return <></>

  return (
    <SpacingContainer
      margin_top={spacingMarginTop}
      margin_bottom={spacingMarginBottom}
      padding_top={spacingPaddingTop}
      padding_bottom={spacingPaddingBottom}
      background_color={backgroundColor}
    >
      <Container>
        <div className="grid grid-cols-24">
          <div className="col-span-24 text-center text-spinach">
            <div className="aspect-h-22 aspect-w-17 overflow-hidden rounded-8 md:aspect-h-9 md:aspect-w-16">
              <div className="md:hidden">
                <Image
                  src={props.media?.permalink}
                  span={12}
                  alt={props.media?.alt}
                  focal_points={props.media?.focus}
                  aspect_ratio="17:22"
                  loading={props.is_above_fold ? "eager" : undefined}
                  className="object-cover"
                  fill
                />
              </div>
              <div className="hidden md:block">
                <Image
                  src={props.media?.permalink}
                  span={12}
                  alt={props.media?.alt}
                  focal_points={props.media?.focus}
                  aspect_ratio="16:9"
                  loading={props.is_above_fold ? "eager" : undefined}
                  className="object-cover"
                  fill
                />
              </div>
              <div className="flex flex-col justify-between p-24 text-left md:items-center md:justify-center md:text-center">
                {props.headline && (
                  <h2 className="mb-32 mt-8 text-32 font-medium leading-36 text-white md:mt-0 md:text-48 md:leading-48">
                    {props.headline}
                  </h2>
                )}
                <CmsLink cta={props.cta}>
                  <CmsButton cta={props.cta} appearance="large" />
                </CmsLink>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </SpacingContainer>
  )
}

export default ImageWithCta
